import request from "@/utils/axios.js";
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  async getOverview() {
    request.get("/admin/overview").then((response) => {
      let data = response.data;

      this.users = data.users;
      this.keys = data.keys;
      this.resets = data.resets;
      this.status = data.cheat.status == "online" ? 1 : 0;
    });
  },
  async updateCheat() {
    let formData = new FormData();
    let dllFile = document.getElementById("dllUpload").files[0];
    let loaderFile = document.getElementById("loaderUpload").files[0];

    if (dllFile) {
      formData.append("dll", dllFile, dllFile.name);
    }

    if (loaderFile) {
      formData.append("loader", loaderFile, loaderFile.name);
    }

    formData.append("status", this.status);
    request.post("/admin/cheat", formData);
  },
  async editUser(userId) {
		let sub_options = "";
    let user = this.users.find((user) => {
      return user.id == userId;
    });

    if (!user.subscription) {
      user.subscription = {
        type: 0,
        expires_at: moment().format("DD/MM/YYYY"),
      };
    }

    this.types.forEach((type, index) => {
      let selected = "";

      if (index == user.subscription.type) {
        selected = "selected";
      }

      sub_options = sub_options + `
				<option value="${index}" ${selected}>${type}</option>
			`;
    });

    const { value: formValues } = await Swal.fire({
      title: "",
      html: `
				<input id="swal_name" type="text" class="swal2-input" value="${user.name}">
				<input id="swal_email" type="email" class="swal2-input" value="${user.email}">
				<input id="swal_pass" type="password" class="swal2-input" placeholder="password">
				<input id="swal_hwid" type="text" class="swal2-input" value="${user.hwid ?? ''}" placeholder="hwid">
				<select class="swal2-select swal2-custom-select" id="swal_sub_type">
					<option disabled>Select a sub type</option>

					<optgroup label="types">
						${sub_options}
					</optgroup>
				</select>
				<input id="swal_sub_expires" type="text" class="swal2-input" value="${user.subscription.expires_at}">
			`,
      showCancelButton: true,
      confirmButtonText: "Save",
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal_name").value,
          document.getElementById("swal_email").value,
          document.getElementById("swal_pass").value,
          document.getElementById("swal_hwid").value,
          document.getElementById("swal_sub_type").value,
          document.getElementById("swal_sub_expires").value,
        ];
      },
    });

    if (!formValues) {
      return;
    }

    request.post("/admin/user/" + user.id, {
			name: formValues[0],
			email: formValues[1],
			password: formValues[2],
			hwid: formValues[3],
			type: formValues[4],
			expires_at: formValues[5],
		})
      .then(() => {
        this.getOverview();
      });
  },
  async deleteUser(userId) {
    Swal.fire({
      title: "Delete user?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      focusConfirm: false,
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }

      this.users = this.users.filter((user) => user.id != userId);
      request.delete("/admin/user/" + userId);
    });
  },
  async createKey() {
    let types = this.types;

    const { value: type } = await Swal.fire({
      title: "",
      input: "select",
      inputOptions: {
        types,
      },
      inputPlaceholder: "Select a sub type",
      showCancelButton: true,
      confirmButtonText: "Create",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value == 0) {
            resolve("You need to select a valid sub type :)");
          } else {
            resolve();
          }
        });
      },
    });

    if (!type) {
      return;
    }

    request.post("/admin/key", { type: type }).then(() => {
      this.getOverview();
    });
  },
  async deleteKey(keyId) {
    this.keys = this.keys.filter((key) => key.id != keyId);
    request.delete("/admin/key/" + keyId);
  },
	async handleReset(action, resetId) {
    this.resets = this.resets.filter((reset) => reset.id != resetId);

		request.post("/admin/reset/" + resetId, {
			'action': action
		});
	}
};
