<template>
  <div class="dash">
    <section id="heading" class="container">
      <h2>
        manage cheat
        <small>lorem ipsum dolor sit amet</small>
      </h2>
    </section>

    <section id="cheat" class="container article">
      <form
        class="card card_large fluid manage_cheat"
        id="cheat_form"
        @submit.prevent="updateCheat()"
        enctype="multipart/form-data"
      >
        <label>status</label>

        <select v-model="this.status">
          <option value="1">online</option>
          <option value="0">offline</option>
        </select>

        <label>dll</label>
        <input type="file" id="dllUpload" accept=".dll" />

        <label>loader</label>
        <input type="file" id="loaderUpload" accept=".exe" />

        <button>Update</button>
      </form>
    </section>

    <section id="heading" class="container">
      <h2>
        user list
        <small>lorem ipsum dolor sit amet</small>
      </h2>
    </section>

    <section id="users" class="container article">
      <div class="table-responsive card fluid">
        <table>
          <thead>
            <tr>
              <th class="hide_mobile">#</th>
              <th class="hide_mobile">name</th>
              <th>email</th>
              <th>type</th>
              <th>date</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in this.users" :key="index">
              <td class="hide_mobile">
                {{ user.id }}
              </td>

              <td>
                {{ user.name }}
              </td>

              <td>{{ user.email }}</td>

              <template v-if="user.subscription != null">
                <td>{{ this.types[user.subscription.type] }}</td>
                <td>{{ user.subscription.expires_at }}</td>
              </template>

              <template v-else>
                <td>expired</td>
                <td>invalid</td>
              </template>

              <td>
                <button
                  class="action_edit"
                  @click.prevent="editUser(user.id)"
                  v-text="'Edit'"
                />

                <button
                  class="action_edit action_delete"
                  @click.prevent="deleteUser(user.id)"
                  v-text="'Delete'"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section id="heading" class="container heading_item">
      <h2>
        key list
        <small>lorem ipsum dolor sit amet</small>
      </h2>

      <button @click.prevent="createKey()">create</button>
    </section>

    <section id="keys" class="container article">
      <div class="table-responsive card fluid">
        <table>
          <thead>
            <tr>
              <th>code</th>
              <th>type</th>
              <th>by</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(key, index) in this.keys" :key="index">
              <td>{{ key.code }}</td>
              <td>{{ this.types[key.type] }}</td>
              <td>{{ key.admin.email }}</td>
              <td>
                <button class="action_edit" @click.prevent="deleteKey(key.id)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <section id="heading" class="container">
      <h2>
        reset requests
        <small>lorem ipsum dolor sit amet</small>
      </h2>
    </section>

    <section id="resets" class="container article">
      <div class="table-responsive card fluid">
        <table>
          <thead>
            <tr>
              <th>user</th>
              <th>reason</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(reset, index) in this.resets" :key="index">
              <td>{{ reset.user.email }}</td>
              <td>{{ reset.reason }}</td>
              <td>
                <button
                  class="action_edit"
                  @click.prevent="handleReset('aprove', reset.id)"
                >
                  Aprove
                </button>
                <button
                  class="action_edit action_delete"
                  @click.prevent="handleReset('deny', reset.id)"
                >
                  Deny
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import Admin from "@/services/admin.js";
import "@/assets/css/dash.css";

export default {
  data() {
    return {
      users: [],
      keys: [],
      resets: [],
      types: ["expired", "mensal", "lifetime"],
      status: 0,
    };
  },
  methods: {
    ...Admin,
  },
  async mounted() {
    this.getOverview();
  },
};
</script>

<style scoped>
.manage_cheat {
  padding: 15px 20px;
  align-items: flex-start!important;
  flex-direction: column;
}

.heading_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
